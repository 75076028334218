import React, { useState, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import FAQItem from "../components/FAQItem";
import { faqData } from "../data/faqData";
import banner from "../assets/footer-bg.svg";

const FAQSection = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  useEffect(() => {
    AOS.init({ duration: 1200 });
  }, []);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div
      className="bg-[#02031E] py-16 px-4"
      style={{
        backgroundImage: `url(${banner})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      <div className="max-w-3xl mx-auto">
        <h2
          className="text-4xl font-bold text-center text-white mb-8"
          data-aos="fade-up"
        >
          Dúvidas Frequentes
        </h2>
        {faqData.map((faq, index) => (
          <FAQItem
            key={index}
            faq={faq}
            index={index}
            activeIndex={activeIndex}
            toggleFAQ={toggleFAQ}
          />
        ))}
      </div>
    </div>
  );
};

export default FAQSection;
