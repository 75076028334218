import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { benefits } from "../data/benefitsData";

const BenefitsSection = () => {
  useEffect(() => {
    AOS.init({ duration: 1200, once: true });
  }, []);

  return (
    <div className="container mx-auto px-4">
      <h2
        className="text-3xl font-bold text-[#02031e] mb-16 text-center"
        data-aos="fade-up"
      >
        Benefícios de uma Gestão Financeira Eficiente
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
        {benefits.map((benefit, index) => (
          <div
            key={index}
            className={`${benefit.bgColor} flex flex-col items-center p-6 rounded-lg shadow-lg`}
            data-aos="fade-up"
            data-aos-delay={benefit.delay}
          >
            {benefit.icon}
            <h2 className="text-xl font-semibold text-white mb-2">
              {benefit.title}
            </h2>
            <p className="text-white text-center">{benefit.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BenefitsSection;
