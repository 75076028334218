import { FaExclamationCircle, FaRegFrown, FaCreditCard, FaTimesCircle } from "react-icons/fa";

export const featuresData = [
  { icon: <FaTimesCircle />, title: "Sempre termina o mês no negativo" },
  {
    icon: <FaRegFrown />,
    title: "Acredita que não ganha o suficiente para fazer dinheiro sobrar",
  },
  {
    icon: <FaRegFrown />,
    title: "Não sabe como viver bem sem gastar o que não tem",
  },
  { icon: <FaCreditCard />, title: "É refém da fatura no cartão de crédito" },
  {
    icon: <FaExclamationCircle />,
    title: "Sente que não consegue aproveitar a vida por falta de dinheiro",
  },
  {
    icon: <FaExclamationCircle />,
    title: "Todos ao seu redor tem tudo menos você",
  },
];
