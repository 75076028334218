export const faqData = [
    {
      question: "Em quanto tempo recebo o acesso?",
      answer: "Imediatamente! Logo após a aprovação do seu pagamento, você irá receber no seu e-mail de compra os seus dados de login e então poderá começar imediatamente os seus estudos.",
    },
    {
      question: "O pagamento é seguro?",
      answer: "Sim! O pagamento é 100% seguro, processado com criptografia. Seguimos A Lei Geral de Proteção de Dados Pessoais (LGPD) Lei n° 13.709, de 2018.",
    },
    {
      question: "Em quantos computadores ou smartphones posso usar as planilhas?",
      answer: "Você pode baixar e utilizar em todos os dispositivos de forma livre.",
    },
    {
      question: "Preciso ter algum conhecimento específico para usar as planilhas?",
      answer: "Qualquer pessoa com um mínimo de conhecimento em Excel pode utilizar este pacote de planilhas para facilitar o seu trabalho.",
    },
    {
      question: "Vai ter um tutorial explicando como usar a planilha?",
      answer: "Sim, você vai ter acesso a videos explicativos.",
    },
    {
      question: "Existe um suporte ou equipe de atendimento?",
      answer: "Existe sim, estaremos disponíveis via e-mail contato@planilhacontroletotal.com",
    },
    {
      question: "É seguro armazenar minhas informações financeiras na planilhacontroletotal?",
      answer: "Sim, a partir do momento que você adquiriu a planilha somente você terá acesso as informações que você preenche.",
    },
    {
      question: "Essa planilha é para qualquer pessoa?",
      answer: "Sim, é para aquelas pessoas que que querem fazer sobrar o dinheiro e gastar de forma inteligente.",
    },
  ];
  