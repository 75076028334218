import React from "react";
import Background from "./Background.js";

const Layout = ({ children }) => {
  return (
    <div className="relative">
      <Background />
      <div className="relative z-10">{children}</div>
    </div>
  );
};

export default Layout;
