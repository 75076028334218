import {
    FaExclamationTriangle,
    FaChartLine,
    FaMoneyBillWave,
    FaCogs,
  } from "react-icons/fa";
  
  export const benefits = [
    {
      icon: <FaExclamationTriangle className="text-4xl text-white mb-4" />,
      title: "Redução de Riscos",
      description:
        "Identifique e mitigue riscos financeiros antes que eles se tornem problemas graves para sua empresa.",
      delay: 300,
      bgColor: "gradient-background",
    },
    {
      icon: <FaChartLine className="text-4xl text-white mb-4" />,
      title: "Crescimento Sustentável",
      description:
        "Planeje e acompanhe o crescimento de sua empresa com base em dados precisos e bem geridos.",
      delay: 400,
      bgColor: "gradient-background",
    },
    {
      icon: <FaMoneyBillWave className="text-4xl text-white mb-4" />,
      title: "Controle Financeiro",
      description:
        "Mantenha controle total sobre suas finanças e evite surpresas desagradáveis.",
      delay: 500,
      bgColor: "gradient-background",
    },
    {
      icon: <FaCogs className="text-4xl text-white mb-4" />,
      title: "Eficiência Operacional",
      description:
        "Melhore a eficiência operacional através de uma gestão financeira organizada e eficiente.",
      delay: 600,
      bgColor: "gradient-background",
    },
  ];
  