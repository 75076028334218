import React from "react";
import FeedbackSlide from "../components/FeedBack/FeedbackSlide";
import { feedbacksData } from "../data/feedbacksData";

const FeedbackPage = () => {
  return (
    <div className="gradient-background">
      <svg
        className="mx-auto w-16 h-auto"
        viewBox="0 0 100 100"
        xmlns="http://www.w3.org/2000/svg"
        fill="#00adef"
      >
        <path fillOpacity="1" d="M0,0L50,30L100,0Z"></path>
      </svg>
      <div className="container mx-auto py-20 px-4">
        <h2 className="text-3xl font-bold text-center mb-8 text-white">
          Veja o que as pessoas estão comentando <br />
          <p className="font-semibold text-xl text-center mt-8 mb-8 text-white">
            Desde fazer sobrar dinheiro todos os meses, juntar para reserva de
            emergência, ter tranquilidade e estabilidade financeira
          </p>
        </h2>
        <FeedbackSlide feedbacks={feedbacksData} />
      </div>
    </div>
  );
};

export default FeedbackPage;
