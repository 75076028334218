import React from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "tailwindcss/tailwind.css";
import icon1 from "../assets/DoubtPage/1.webp";
import icon2 from "../assets/DoubtPage/2.webp";
import icon3 from "../assets/DoubtPage/3.webp";
import icon4 from "../assets/DoubtPage/4.webp";

const DoubtSection = () => {
  React.useEffect(() => {
    AOS.init({ duration: 1200, once: true });
  }, []);

  const icons = [icon1, icon2, icon3, icon4];

  return (
    <section className="bg-[#00adef]">
      <div className="py-16 overflow-hidden">
        <div className="container mx-auto px-4 md:px-8 relative z-10">
          <h2
            className="text-4xl font-bold text-white mb-6 text-center"
            data-aos="fade-up"
          >
            Ainda em Dúvida?
          </h2>
          <p
            className="text-xl text-white mb-12 text-center max-w-4xl mx-auto"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            Caso você ainda não tenha certeza se essa planilha é realmente para
            você, vou te fazer 4 perguntas. Se as respostas para elas forem
            <strong className="ml-2">SIM</strong>, este material vai te ajudar.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {questions.map((question, index) => (
              <div
                key={index}
                className="relative gradient-background p-8 rounded-lg shadow-lg overflow-hidden transition-transform transform hover:scale-105"
                data-aos="fade-up"
                data-aos-delay={(index + 1) * 300}
              >
                <div className="absolute inset-0 rounded-lg -z-10"></div>
                <div className="flex items-center mb-4">
                  <div
                    className={`w-16 h-16 flex items-center justify-center rounded-full overflow-hidden`}
                  >
                    <img
                      src={icons[index]}
                      alt={`Icon ${index + 1}`}
                      loading="lazy"
                      className="w-full h-full object-contain"
                    />
                  </div>
                  <h2 className="text-xl font-semibold text-white ml-4">
                    {question.title}
                  </h2>
                </div>
                <p className="text-white">{question.description}</p>
              </div>
            ))}
          </div>
          <div className="text-center mt-12">
            <p className="text-xl font-semibold text-white mb-4">
              Se você respondeu <strong>SIM</strong> para alguma dessas
              perguntas, nossa planilha é perfeita para você!
            </p>
            <a
              href="https://pay.kiwify.com.br/z5lQvhc"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-block gradient-background mt-4 text-white uppercase py-3 px-6 rounded-lg text-lg font-semibold transition-transform transform hover:scale-110"
            >
              Quero começar agora
            </a>
          </div>
        </div>
      </div>
      <svg
        className="w-full h-auto"
        viewBox="0 0 1430 70"
        xmlns="http://www.w3.org/2000/svg"
        fill="#02031E"
      >
        <path fillOpacity="1" d="M1430,70L0,40L0,80L1430,80Z"></path>
      </svg>
    </section>
  );
};

const questions = [
  {
    title: "Você sabe quais são os seus gastos fixos?",
    description:
      "Identifique se você tem clareza sobre seus gastos fixos e saiba onde pode economizar.",
  },
  {
    title: "Você tem controle das contas da sua casa?",
    description:
      "Avalie se você tem um controle adequado das contas do dia a dia e se consegue acompanhar seus pagamentos.",
  },
  {
    title: "O seu dinheiro acaba antes do final do mês?",
    description:
      "Determine se o seu orçamento está sendo gerido corretamente e se você consegue planejar para o mês inteiro.",
  },
  {
    title: "Você tem o hábito de comprar por impulso?",
    description:
      "Verifique se você costuma fazer compras não planejadas e como isso afeta suas finanças.",
  },
];

export default DoubtSection;
