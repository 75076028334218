import React from "react";
import icon1 from "../assets/IconsFooter/1.webp";
import icon2 from "../assets/IconsFooter/2.webp";
import icon3 from "../assets/IconsFooter/3.webp";
import brivMedia from "../assets/IconsFooter/brivmedia.png";

const Footer = () => {
  return (
    <footer className="gradient-background-footer text-white py-4">
      <div className="text-foreground p-8 text-center">
        <h1 className="text-2xl md:text-5xl font-bold mb-4">
          A vida que você sempre quis <br /> está na sua mão!
        </h1>
        <p className="text-xl md:text-2xl text-muted-foreground mb-8">
          Seu futuro só depende da sua decisão
        </p>
        <div className="flex justify-center space-x-8">
          <div className="flex items-center space-x-2">
            <img src={icon1} loading="lazy" className="w-10 h-10" />
            <div className="flex flex-col items-start">
              <span className="text-sm">Compra</span>
              <span className="text-sm">Segura</span>
            </div>
          </div>
          <div className="flex items-center space-x-2">
            <img src={icon2} loading="lazy" className="w-10 h-10" />
            <div className="flex flex-col items-start">
              <span className="text-sm">Satisfação</span>
              <span className="text-sm">Garantida</span>
            </div>
          </div>
          <div className="flex items-center space-x-2">
            <img src={icon3} loading="lazy" className="w-10 h-10" />
            <div className="flex flex-col items-start">
              <span className="text-sm">Privacidade</span>
              <span className="text-sm">Protegida</span>
            </div>
          </div>
        </div>
        <p className="text-xl md:text-2xl text-muted-foreground mt-10 mb-8">
          Desenvolvido por:{" "}
          <a
            href="https://instagram.com/briv.media"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white font-bold transition-all inline-flex items-center"
          >
            <img
              src={brivMedia}
              loading="lazy"
              className="w-full h-5 ml-2"
              alt="Briv•Media"
            />
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
