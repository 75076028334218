import {
    FaDollarSign,
    FaUmbrellaBeach,
    FaChartBar,
    FaGlobe,
    FaChartLine,
    FaTasks,
  } from "react-icons/fa";
  
  export const bonusItems = [
    {
      icon: <FaDollarSign className="text-3xl text-[#fff]" />,
      title: "Simulador de Poupança",
      description:
        "Simule como suas economias podem crescer ao longo do tempo com nossos cálculos detalhados e fáceis de usar.",
    },
    {
      icon: <FaUmbrellaBeach className="text-3xl text-[#fff]" />,
      title: "Simulador de Aposentadoria",
      description:
        "Planeje sua aposentadoria de forma eficiente com ferramentas de simulação personalizadas.",
    },
    {
      icon: <FaChartBar className="text-3xl text-[#fff]" />,
      title: "Método de Gastos",
      description:
        "Controle seus gastos e descubra como otimizar seu orçamento com nosso método eficiente.",
    },
    {
      icon: <FaGlobe className="text-3xl text-[#fff]" />,
      title: "Planejamento de Viagens",
      description:
        "Organize suas viagens com precisão e evite surpresas financeiras durante suas aventuras.",
    },
    {
      icon: <FaChartLine className="text-3xl text-[#fff]" />,
      title: "Simulador de Investimentos",
      description:
        "Avalie suas opções de investimento e veja como elas podem impactar seu futuro financeiro.",
    },
    {
      icon: <FaTasks className="text-3xl text-[#fff]" />,
      title: "Lista de Tarefas",
      description:
        "Tenha controle da sua performance e mensure sua criação de mini hábitos.",
    },
  ];
  