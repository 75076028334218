import React, { useEffect } from "react";
import ModalVideo from "../components/ModalVideoVSL";
import AOS from "aos";
import "aos/dist/aos.css";

const VSL = () => {
  useEffect(() => {
    AOS.init({ once: true });
  }, []);

  return (
    <section className="text-gray-900" id="vsl">
      <div className="mx-auto text-center px-4 md:px-8">
        <ModalVideo />
        <div 
          className="mt-md mb-lg relative inline-flex items-center justify-center w-full"
          data-aos="fade-up"
          data-aos-duration="800"
          data-aos-delay="700"
        >
          <div className="text-center mt-6 mb-6">
            <a href="https://pay.kiwify.com.br/z5lQvhc" target="_blank" rel="noopener noreferrer">
              <button className="gradient-button uppercase text-white font-bold py-3 px-12 rounded-md border-b-4 border-transparent transition-all duration-300 hover:shadow-md hover:shadow-[#02031E80] hover:border-[#02031E]">
                <span className="hidden lg:inline">
                  Quero ter o controle total do meu dinheiro
                </span>
                <span className="inline lg:hidden">
                  Quero ter o controle <br />
                  total do meu dinheiro
                </span>
              </button>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default VSL;
