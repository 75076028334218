import React from "react";

const ModalVideo = () => {
  return (
    <div className="inset-0 z-[99999] flex items-center justify-center px-4 md:px-6 py-6">
      <div
        className="relative w-full max-w-5xl bg-black rounded-3xl overflow-hidden"
        data-aos="fade-up"
        data-aos-duration="700"
        data-aos-delay="600"
      >
        <div
          className="wistia_responsive_padding"
          style={{ paddingTop: '56.25%', position: 'relative' }}
        >
          <div
            className="wistia_responsive_wrapper"
            style={{ height: '100%', position: 'absolute', top: 0, left: 0, width: '100%' }}
          >
            <div
              className="wistia_embed wistia_async_auesbzdqge "
              style={{ height: '100%', position: 'relative', width: '100%' }}
            >
              &nbsp;
            </div>
          </div>
        </div>
       
      </div>
    </div>
  );
};

export default ModalVideo;
