import casal1 from "../assets/feedback/casal-1.webp";
import homem1 from "../assets/feedback/homem-1.webp";
import homem2 from "../assets/feedback/homem-2.webp";
import mulher1 from "../assets/feedback/mulher-1.webp";
import mulher2 from "../assets/feedback/mulher-2.webp";
import mulher3 from "../assets/feedback/mulher-3.webp";
import mulher4 from "../assets/feedback/mulher-4.webp";

export const feedbacksData = [
  {
    customerName: "Caio dos Santos Silva",
    customerPhoto: casal1,
    message: "Ótima planilha! O financeiro era um grande obstátulo no meu negócio junto com a minha esposa e nós estamos maravilhados com os resultados!",
    sellerResponse: "Obrigado, Caio! Desejamos que a planilha continue sendo de grande utilidade.",
  },
  {
    customerName: "Renato Oliveira",
    customerPhoto: homem1,
    message: "Superaram minhas expectativas e o valor está super em conta pelo retorno financeiro que estou obtendo graças ao que esta planilha está me proporcionando.",
    sellerResponse: "Obrigado pelo feedback, Renato! Desejamos que a planilha continue te proporcionando ótimos resultados!",
  },
  {
    customerName: "Carlos Pereira Goulart",
    customerPhoto: homem2,
    message: "Em apenas um mês de uso já notei dinheiro sobrando no caixa e metas sendo atingidas e superadas.",
    sellerResponse: "Agradecemos pelo seu feedback, Carlos! Desejamos que esta planilha continue sendo de grande utilidade para você!",
  },

  {
    customerName: "Stephanie Souza Maia",
    customerPhoto: mulher1,
    message: "Estou maravilhada com tantas funcionalidades. Tudo oque eu preciso em uma só planilha, valeu super a pena!",
    sellerResponse: "Ficamos felizes com o seu feedback, estruturamos tudo com o máximo de possibilidades e controle possível para ser funcional.",
  },
  {
    customerName: "Patrícia Da Cruz",
    customerPhoto: mulher2,
    message: "Ótima planilha! Organizou todos os meus fluxos e ainda me deu um alívio financeiro para tirar umas férias.",
    sellerResponse: "Patrícia, muito obrigada pelo feedback. Fizemos essa planilha pensando em controle financeiro mas também em qualidade de vida! Você merece.",
  },
  {
    customerName: "Lohaine Menezes",
    customerPhoto: mulher3,
    message: "Fenomenal! Me ajudou muito na organização financeira do meu negócio. Tenho notado diferenças muito significativas em pouco tempo de uso!",
    sellerResponse: "Agradecemos pelo seu feedback, Lohaine! Desejamos que continue sendo de grande utilidade para você!",
  },
  {
    customerName: "Larissa Rocha Viana",
    customerPhoto: mulher4,
    message: "Eu pensava que tinha controle financeiro da minha empresa até conhecer esta planilha. Muito boa!",
    sellerResponse: "Ficamos felizes com o seu feedback. Desejamos que a Planilha Controle Total continue sendo o alicerce do seu financeiro de sucesso!",
  },
];
