import React from "react";
import banner from "../assets/banner-bg.svg";
import VSL from "./VSL";
import gif from "../assets/gif.gif";

const Home = () => {
  return (
    <header className="header-area">
      <div
        id="home"
        className="header-hero bg-cover"
        style={{
          backgroundImage: `url(${banner})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <div className="container mx-auto px-6">
          <div className="flex flex-col lg:flex-row items-center lg:justify-between mt-md mb-lg">
            <div className="lg:w-1/2 text-center lg:text-left mb-8 lg:mb-0 mt-xs lg:mt-sm">
              <h2 className="text-2xl md:text-base lg:text-4xl font-bold mb-8 text-white">
                Copie e cole uma planilha pronta
                <span className="ml-2 text-[#00ADEF]">
                  para organizar sua vida financeira em 30 dias e veja seu
                  dinheiro sobrando desde o primeiro mês de uso
                </span>
              </h2>
              <div className="text-lg md:text-base lg:text-2xl text-white">
                <p className="hidden lg:inline">
                  Gerencie o seu dinheiro de forma simples, prática e rápida,
                  mesmo sem conhecimento em finanças ou Excel
                </p>
                <p className="inline lg:hidden">
                  Gerencie o seu dinheiro de forma <br />
                  simples, prática e rápida, mesmo sem conhecimento em finanças
                  ou Excel
                </p>
              </div>
            </div>
            <div className="lg:w-1/2 text-center lg:text-right">
              <div
                className="header-hero-image wow fadeIn"
                data-wow-duration="1.3s"
                data-wow-delay="1.4s"
              >
                <img
                  src={gif}
                  alt="hero"
                  loading="lazy"
                  className="mx-auto lg:ml-auto lg:mr-0 sm:mt-lg"
                />
              </div>
            </div>
          </div>
        </div>
        <VSL />
      </div>
    </header>
  );
};

export default Home;
