import React, { useState, useEffect } from "react";
import logo from "../assets/logo.webp";
import "../styles/global.css";

const Header = () => {
  const calculateTimeRemaining = () => {
    const now = new Date();
    const end = new Date();
    end.setHours(24, 0, 0, 0);

    const timeDiff = end - now;
    const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

    return { days, hours, minutes, seconds };
  };

  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <header className="w-full pt-4 bg-[#02031e] text-white">
      <div className="container mx-auto px-6">
        <div className="flex flex-col lg:flex-row justify-between items-center">
          <div className="flex items-center mb-4 lg:mb-0">
            <img src={logo} loading="lazy" alt="Logo" className="h-20 lg:h-28" />
          </div>

          <div className="flex-1 flex items-center justify-center mt-4 mb-4 lg:mb-0">
            <span
              className="text-xs md:text-base lg:text-xl font-semibold text-yellow uppercase animate-pulse"
              data-aos="fade-zoom-in-out"
              data-aos-duration="1500"
              data-aos-delay="200"
            >
              Oferta especial expira em
            </span>
          </div>

          <div className="flex flex-wrap justify-center lg:justify-center space-x-2 lg:space-x-4">
            {["Dias", "Horas", "Minutos", "Segundos"].map((label, index) => (
              <div
                key={label}
                className="flex flex-col items-center bg-[#00ADEF] text-white p-2 rounded-lg shadow-md mb-2 lg:mb-0 text-xs md:text-base lg:text-lg"
                data-aos="fade-up"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="1500"
              >
                <span className="text-xs md:text-base lg:text-xl font-bold">
                  {Object.values(timeRemaining)[index]}
                </span>
                <span className="text-xs uppercase tracking-wide">{label}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
