import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import experiences from "../data/timeline";

const ExperienceCard = ({ title, icon, iconBg, points }) => (
  <VerticalTimelineElement
    contentStyle={{
      background: "#fff",
      color: "#02031e",
      boxShadow: "0 8px 16px #02031e",
      borderBottom: "4px solid #02031e",
    }}
    contentArrowStyle={{ borderRight: "7px solid #fff" }}
    iconStyle={{ background: iconBg }}
    icon={
      <div className="flex h-full w-full items-center justify-center">
        <img
          src={icon}
          alt={title}
          loading="lazy"
          className="h-[100%] w-[100%] object-contain"
        />
      </div>
    }
  >
    <div className="custom-footer">
      <h2
        className="text-3xl font-bold text-left"
        style={{ fontSize: "1.5rem", color: "#02031e" }}
      >
        {title}
      </h2>
      <div className="ml-5 mt-5 list-disc space-y-2">
        {points.map((point, index) => (
          <h3
            key={`experience-point-${index}`}
            className="text-white-100 pl-1 text-lg tracking-wider"
          >
            {point}
          </h3>
        ))}
      </div>
    </div>
  </VerticalTimelineElement>
);

const Experience = () => (
  <>
    <div className="gradient-background-timeline flex flex-col">
      <h2 className="text-4xl font-bold text-center text-white mb-8">
        Benefícios de uma Gestão Financeira Eficiente
      </h2>
      <VerticalTimeline>
        {experiences.map((experience, index) => (
          <ExperienceCard key={index} {...experience} />
        ))}
      </VerticalTimeline>
    </div>
  </>
);

const TimeLineFinance = () => <Experience />;

export default TimeLineFinance;
