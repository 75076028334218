import icon1 from "../assets/iconsTimeLine/1.webp";
import icon2 from "../assets/iconsTimeLine/2.webp";
import icon3 from "../assets/iconsTimeLine/3.webp";
import icon4 from "../assets/iconsTimeLine/4.webp";
import icon5 from "../assets/iconsTimeLine/5.webp";
import icon6 from "../assets/iconsTimeLine/6.webp";

const experiences = [
  {
    title: "Sempre termina o mês no negativo",
    icon: icon1,
    iconBg: "linear-gradient(to left, #02031e 30%, #00adef 100%)",
    points: [
      "Entender suas despesas e receitas é essencial para não terminar o mês no vermelho. Nossas ferramentas e planilhas ajudam você a organizar suas finanças e evitar surpresas no final do mês.",
    ],
  },
  {
    title: "Acredita que não ganha o suficiente para fazer dinheiro sobrar",
    icon: icon2,
    iconBg: "linear-gradient(to left, #02031e 30%, #00adef 100%)",
    points: [
      "Gerenciar suas finanças de forma eficaz pode ajudar a fazer o dinheiro sobrar, mesmo com uma renda limitada. Nossos recursos oferecem estratégias para otimizar seu orçamento e aumentar suas economias.",
    ],
  },
  {
    title: "Não sabe como viver bem sem gastar o que não tem",
    icon: icon3,
    iconBg: "linear-gradient(to left, #02031e 30%, #00adef 100%)",
    points: [
      "Aprenda a viver dentro do seu orçamento e ainda aproveitar a vida. Com nossas ferramentas, você pode planejar suas despesas e evitar dívidas desnecessárias.",
    ],
  },
  {
    title: "É refém da fatura no cartão de crédito",
    icon: icon4,
    iconBg: "linear-gradient(to left, #02031e 30%, #00adef 100%)",
    points: [
      "Controle suas despesas com cartão de crédito e evite os juros altos. Nossas planilhas ajudam você a monitorar seus gastos e manter o controle financeiro.",
    ],
  },
  {
    title: "Sente que não consegue aproveitar a vida por falta de dinheiro",
    icon: icon5,
    iconBg: "linear-gradient(to left, #02031e 30%, #00adef 100%)",
    points: [
      "Com uma boa gestão financeira, você pode aproveitar a vida sem se preocupar com a falta de dinheiro. Nossos recursos ajudam você a equilibrar suas finanças e desfrutar dos momentos importantes.",
    ],
  },
  {
    title: "Todos ao seu redor tem tudo menos você",
    icon: icon6,
    iconBg: "linear-gradient(to left, #02031e 30%, #00adef 100%)",
    points: [
      "A comparação pode ser desmotivadora, mas com um planejamento financeiro sólido, você pode alcançar seus objetivos e viver de acordo com suas próprias possibilidades.",
    ],
  },
];

export default experiences;
