import React from "react";

const FeedbackItem = ({ feedback }) => {
  return (
    <div className="bg-white shadow-lg rounded-lg p-6">
      <div className="flex items-center">
        <img
          src={feedback.customerPhoto}
          alt={feedback.customerName}
          loading="lazy"
          className="w-16 h-16 rounded-full mr-4"
        />
        <div>
          <h2 className="text-xl font-bold">{feedback.customerName}</h2>
          <p className="text-gray-600">{feedback.message}</p>
        </div>
      </div>
      <div className="mt-4 border-t pt-4">
        <h2 className="text-lg font-semibold">Resposta</h2>
        <p className="text-gray-700">{feedback.sellerResponse}</p>
      </div>
    </div>
  );
};

export default FeedbackItem;
