import React from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import BenefitsSection from "../components/BenefitsSection";

const ImportancePage = () => {
  React.useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <section className="relative bg-[#00adef] py-6 p-4 overflow-hidden">
      <section className="text-center mb-12">
        <h2
          className="text-4xl font-bold text-[#02031e] mb-4"
          data-aos="fade-up"
        >
          A Importância de Investir na Gestão Financeira
        </h2>
        <p
          className="text-xl text-center text-[#02031e] mb-8 max-w-5xl p-4 mx-auto"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          A ausência de uma gestão financeira eficiente é uma das principais
          causas para a alta taxa de mortalidade em pequenas e médias empresas.
          Sem clareza sobre os dados financeiros, sua empresa pode facilmente se
          tornar parte dos 80% que fecham as portas com menos de um ano
        </p>
      </section>

      <BenefitsSection />

      <section className="text-center py-12 rounded-lg">
        <h2
          className="text-3xl font-bold text-[#02031e] mb-4 leading-8"
          data-aos="fade-up"
        >
          Não deixe sua empresa se tornar uma estatística
        </h2>
        <p
          className="text-1xl text-[#02031e] p-6"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          Invista em uma gestão financeira eficiente e aumente suas chances de
          sucesso
        </p>
        <p
          className="text-1xl text-[#02031e] sm:-mt-10 p-6"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          A Planilha Controle Total pode transformar a forma como você lida com
          o dinheiro e garantir a estabilidade financeira de sua empresa
        </p>
      </section>
    </section>
  );
};

export default ImportancePage;
