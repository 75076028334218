import React from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import icon1 from "../assets/OrganizationPage/1.webp";
import icon2 from "../assets/OrganizationPage/2.webp";
import icon3 from "../assets/OrganizationPage/3.webp";
import icon4 from "../assets/OrganizationPage/4.webp";
import icon5 from "../assets/OrganizationPage/5.webp";
import icon6 from "../assets/OrganizationPage/6.webp";

const OrganizationPage = () => {
  React.useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const icons = [icon1, icon2, icon3, icon4, icon5, icon6];
  const iconComponents = icons.map((icon, index) => (
    <div
      key={index}
      className="w-24 h-24 flex items-center justify-center mb-4 rounded-full text-white icon-container"
    >
      <img
        src={icon}
        alt={`Icon ${index + 1}`}
        loading="lazy"
        className="h-24 w-24 object-contain"
      />
    </div>
  ));

  return (
    <div className="bg-[#02031e]">
      <svg
        className="mx-auto w-16 h-auto"
        viewBox="0 0 100 100"
        xmlns="http://www.w3.org/2000/svg"
        fill="#00adef"
      >
        <path fillOpacity="1" d="M0,0L50,30L100,0Z"></path>
      </svg>
      <div className="px-4 md:px-6 lg:px-12">
        <section className="text-center mb-12">
          <h2 className="text-4xl font-bold text-white mb-8" data-aos="fade-up">
            A organização começa com uma base, um método e um objetivo
          </h2>
          <span
            className="text-lg text-white mb-8"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            A ferramenta é um meio para você alcançar saúde financeira com
            eficiência através de dados
          </span>
        </section>

        <section className="-mb-4">
          <div className="container mx-auto px-4">
            <h2
              className="text-3xl font-bold text-white mb-[6rem] text-center"
              data-aos="fade-up"
            >
              Organize-se com Eficácia
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8 text-center">
              <div
                className="flex flex-col items-center p-6 bg-white rounded-lg shadow-lg card-container"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                {iconComponents[0]}
                <h2 className="text-xl font-semibold text-[#02031E] mt-6">
                  Anote os seus gastos
                </h2>
                <p className="text-[#02031E] text-center">
                  Identifique para onde está indo cada centavo
                </p>
              </div>
              <div
                className="flex flex-col items-center p-6 bg-white rounded-lg shadow-lg card-container"
                data-aos="fade-up"
                data-aos-delay="400"
              >
                {iconComponents[1]}
                <h2 className="text-xl font-semibold text-[#02031E] mt-6">
                  Calendários de pagamentos
                </h2>
                <p className="text-[#02031E] text-center">
                  Evite pagar juros antecipando problemas
                </p>
              </div>
              <div
                className="flex flex-col items-center p-6 bg-white rounded-lg shadow-lg card-container"
                data-aos="fade-up"
                data-aos-delay="500"
              >
                {iconComponents[2]}
                <h2 className="text-xl font-semibold text-[#02031E] mt-6">
                  Defina metas financeiras
                </h2>
                <p className="text-[#02031E] text-center">
                  Pare de procrastinar! Metas inteligentes representam objetivos
                  alcançáveis
                </p>
              </div>
              <div
                className="flex flex-col items-center p-6 bg-white rounded-lg shadow-lg card-container"
                data-aos="fade-up"
                data-aos-delay="600"
              >
                {iconComponents[3]}
                <h2 className="text-xl font-semibold text-[#02031E] mt-6">
                  Estabeleça seus limites de forma inteligente
                </h2>
                <p className="text-[#02031E] text-center">
                  Tenha dinheiro sobrando para fazer mais coisas
                </p>
              </div>
              <div
                className="flex flex-col items-center p-6 bg-white rounded-lg shadow-lg card-container"
                data-aos="fade-up"
                data-aos-delay="700"
              >
                {iconComponents[4]}
                <h2 className="text-xl font-semibold text-[#02031E] mt-6">
                  Aprenda a poupar de forma inteligente
                </h2>
                <p className="text-[#02031E] text-center">
                  Pare de fazer dívidas para alimentar seu ego, pense a longo
                  prazo
                </p>
              </div>
              <div
                className="flex flex-col items-center p-6 bg-white rounded-lg shadow-lg card-container"
                data-aos="fade-up"
                data-aos-delay="700"
              >
                {iconComponents[5]}
                <h2 className="text-xl font-semibold text-[#02031E] mt-6">
                  Distribua a sua receita com inteligência
                </h2>
                <p className="text-[#02031E] text-center">
                  Não apenas guarde mas distribua de forma inteligente para
                  investimento, <br /> momentos de lazer e presentes.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="text-center py-12">
          <h2 className="text-4xl font-bold text-white mb-4" data-aos="fade-up">
            Comece Agora Sua Jornada Financeira
          </h2>
          <h3
            className="text-2xl text-white mb-8"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            Utilize nossas ferramentas e estratégias para transformar sua
            abordagem financeira e alcançar a saúde financeira desejada.
          </h3>
          <a
            href="https://pay.kiwify.com.br/z5lQvhc"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block gradient-background text-white uppercase py-3 px-6 rounded-lg text-lg font-semibold transition-transform transform hover:scale-110"
          >
            Quero ter uma vida organizada
          </a>
        </section>
      </div>

      <svg
        width="100%"
        height="auto"
        id="svg"
        viewBox="0 0 1440 140"
        xmlns="http://www.w3.org/2000/svg"
        className="transition duration-300 ease-in-out delay-150"
      >
        <path
          d="M 0,150 L 0,100 C 270,120 540,140 780,140 C 1020,140 1230,120 1440,100 L 1440,150 L 0,150 Z"
          stroke="none"
          strokeWidth="0"
          fill="#00adef"
          fillOpacity="1"
          className="transition-all duration-300 ease-in-out delay-150 path-0"
        ></path>
      </svg>
    </div>
  );
};

export default OrganizationPage;
