import React from "react";
import { featuresData } from "../data/featuresData";

const Features = () => {
  return (
    <section className="relative bg-[#02031E] pb-28 py-18 overflow-hidden">
      <div className="container mx-auto px-4">
        <h2
          className="text-white text-3xl font-bold text-center mb-12"
          data-aos="fade-up"
        >
          Você não precisa entender de finanças
          <br />
          <span className="text-2xl">
            Existem alguns
            <span className="ml-2 bg-[#00adef] text-[#02031E] px-1 rounded">
              sinais de que a sua vida financeira não está caminhando bem
            </span>{" "}
          </span>
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-8">
          {featuresData.map((feature, index) => (
            <div
              key={index}
              className="flex items-start space-x-4 p-4 shadow-custom"
              data-aos="fade-up"
              data-aos-delay={index * 100}
            >
              <div className="text-[#02031E] text-3xl">{feature.icon}</div>
              <div>
                <p className="text-xl">{feature.title}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Features;
