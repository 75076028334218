import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { bonusItems } from "../data/bonusData";

const BonusSection = () => {
  useEffect(() => {
    AOS.init({ duration: 1200, once: true });
  }, []);

  return (
    <div className="bg-[#00adef]">
      <div className="py-16 px-4">
      <div className="text-center mb-12">
        <h2
          className="text-4xl font-extrabold text-white mb-12"
          data-aos="fade-up"
        >
          <span
            className="bg-[#02031E] text-white mb-4 leading-tight rounded"
            data-aos="fade-up"
          >
            Bônus Exclusivos
          </span>{" "}
        </h2>
        <p
          className="text-xl text-white mb-8 max-w-2xl mx-auto"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          Ao adquirir sua planilha financeira,
          <span
            className="ml-2 bg-[#02031E] text-white mb-4 leading-tight rounded"
            data-aos="fade-up"
          >
            você ganha acesso a bônus incríveis
          </span>{" "}
          que vão transformar a sua gestão financeira! Aproveite esses recursos
          adicionais para maximizar o potencial do seu planejamento e alcançar
          suas metas financeiras com mais facilidade.
        </p>
      </div>
      <div className="max-w-6xl mx-auto">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {bonusItems.map((item, index) => (
            <div
              key={index}
              className="gradient-background-bonus p-8 rounded-lg shadow-lg w-full transform transition-transform hover:scale-105 hover:shadow-2xl hover:rotate-3 hover:translate-y-2"
              data-aos="fade-up"
              data-aos-delay={`${300 + index * 100}`}
            >
              <div className="flex justify-center mb-6">
                <div className="flex items-center justify-center w-26 h-26 rounded-full bg-[#02031E]">
                  {item.icon}
                </div>
              </div>
              <h2 className="text-xl font-semibold text-[#00adef] mb-2 text-center">
                {item.title}
              </h2>
              <p className="text-gray-300 text-center text-sm">
                {item.description}
              </p>
            </div>
          ))}
        </div>
      </div>
      </div>
      <svg
        className="w-full h-auto"
        viewBox="0 0 1430 70"
        xmlns="http://www.w3.org/2000/svg"
        fill="#02031E"
      >
        <path fillOpacity="1" d="M1430,70L0,40L0,80L1430,80Z"></path>
      </svg>
    </div>
  );
};

export default BonusSection;
