import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Header from "./components/Header";
import Home from "./page/Home";
import Features from "./page/Features";
import TimeLineFinance from "./page/TimeLineFinance";
import CallToAction from "./page/CallToAction";
import FeedbackPage from "./page/FeedbackPage";
import ImportanceOfFinancialManagement from "./page/ImportanceOfFinancialManagement";
import OrganizationPage from "./page/OrganizationPage";
import DoubtSection from "./page/DoubtSection";
import OfferSection from "./page/OfferSection";
import BonusSection from "./page/BonusSection";
import BackToTopButton from "./components/BackToTopButton";
import FAQSection from "./page/FAQSection";

import Footer from "./components/Footer";
import Layout from "./components/Layout";
import './App.css'

function App() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <Layout>
      <Header />
      <Home />
      <Features />
      <TimeLineFinance />
      <CallToAction />
      <FeedbackPage />
      <ImportanceOfFinancialManagement />
      <OrganizationPage />
      <DoubtSection />
      <OfferSection />
      <BonusSection />
      <FAQSection />
      <BackToTopButton />
      <Footer />
    </Layout>
  );
}

export default App;
