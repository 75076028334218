import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "tailwindcss/tailwind.css";
import check from "../assets/check.webp";

const OfferSection = () => {
  useEffect(() => {
    AOS.init({ duration: 1200, once: true });
  }, []);

  return (
    <div className="bg-[#02031E]">
      <div className=" min-h-screen flex flex-col items-center py-16 px-4">
        <div className="text-center mb-12" data-aos="fade-up">
          <h2 className="text-3xl font-extrabold text-white mb-4">
            Isso é um investimento na sua tranquilidade financeira
          </h2>
        </div>

        <div
          className="bg-white p-8 rounded-lg shadow-lg max-w-lg w-full"
          data-aos="fade-up"
        >
          <div className="text-center mb-6">
            <h3 className="text-[#02031E] font-bold text-lg uppercase">
              Somente hoje você vai ter acesso a todo o conteúdo por apenas:
            </h3>
            <p className="text-[#02031E] font-extrabold text-6xl mt-2">
              5x de R$17,98
            </p>
            <p className="text-[#02031E] font-bold text-2xl mt-2">
              ou R$ 89,90 à vista
            </p>
            <p className="text-[#02031E] font-bold mt-2">RISCO ZERO!</p>
            <p className="text-[#02031E] font-extrabold mt-2">
              MAS VOCÊ PRECISA AGIR RÁPIDO!
            </p>
          </div>

          <ul className="text-left mt-4 font-bold uppercase">
            <li className="flex items-center text-[#02031E]">
              <img
                aria-hidden="true"
                alt="check-mark"
                src={check}
                loading="lazy"
                className="mr-2 h-14 w-14"
              />
              Totalmente editáveis;
            </li>
            <li className="flex items-center text-[#02031E]">
              <img
                aria-hidden="true"
                alt="check-mark"
                src={check}
                loading="lazy"
                className="mr-2 h-14 w-14"
              />
              Acesso Vitalício;
            </li>
            <li className="flex items-center text-[#02031E]">
              <img
                aria-hidden="true"
                alt="check-mark"
                src={check}
                loading="lazy"
                className="mr-2 h-14 w-14"
              />
              Garantia incondicional de 7 dias;
            </li>
            <li className="flex items-center text-[#02031E]">
              <img
                aria-hidden="true"
                alt="check-mark"
                src={check}
                loading="lazy"
                className="mr-2 h-14 w-14"
              />
              Liberação Imediata.
            </li>
          </ul>

          <div className="text-center mt-6">
            <a
              href="https://pay.kiwify.com.br/z5lQvhc"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="inline-block gradient-background mt-4 mb-4 text-white uppercase py-3 px-6 rounded-lg text-lg font-semibold transition-transform transform hover:scale-110">
                QUERO BAIXAR AGORA!
              </button>
            </a>
            <p className="text-gray-500 text-sm mt-2">
              (Últimas horas com valor promocional)
            </p>
          </div>
        </div>
      </div>
      <svg
        className="w-full h-auto"
        viewBox="0 0 1430 70"
        xmlns="http://www.w3.org/2000/svg"
        fill="#00adef"
      >
        <path fillOpacity="1" d="M1430,70L0,40L0,80L1430,80Z"></path>
      </svg>
    </div>
  );
};

export default OfferSection;
