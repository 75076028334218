import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "tailwindcss/tailwind.css";
import {
  FaPiggyBank,
  FaShieldAlt,
  FaBrain,
  FaBolt,
  FaStar,
  FaChartBar,
} from "react-icons/fa";
import CallToAction from "../assets/CallToAction.png";

const FinancialBenefits = () => {
  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);

  return (
    <div className="relative gradient-background-callToAction px-4 overflow-hidden">
      <svg
        className="mx-auto w-16"
        viewBox="0 0 100 100"
        xmlns="http://www.w3.org/2000/svg"
        fill="#00ADEF"
      >
        <path fillOpacity="1" d="M0,0L50,30L100,0Z"></path>
      </svg>

      <div className="bg-overlay absolute inset-0 z-0"></div>
      <div className="relative z-10 max-w-6xl mx-auto text-center">
        <h2 className="text-5xl font-bold text-white mb-12" data-aos="fade-up">
          <span className="bg-[#00adef] text-white px-1 rounded">
            Não importa
          </span>{" "}
          o quanto você ganha
        </h2>
        <p
          className="text-xl text-white mt-8 mb-12"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <span className="bg-[#00adef] text-white px-1 rounded">
            Você só precisa de 5 minutos por dia
          </span>{" "}
          para registrar suas entradas e saídas
          <br />e a Planilha irá separar e organizar seus gastos.
        </p>

        <h2
          className="text-4xl font-semibold text-white mb-12"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          Com nossa planilha financeira você vai:
        </h2>
        <div className="text-left grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <div
            className="flex items-center bg-white p-6 shadow-custom"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            <FaPiggyBank className="text-4xl text-[#02031E] mr-4" />
            <p className="text-lg text-[#02031E]">
              Poupar dinheiro todos os meses
            </p>
          </div>
          <div
            className="flex items-center bg-white p-6 shadow-custom"
            data-aos="fade-up"
            data-aos-delay="400"
          >
            <FaShieldAlt className="text-4xl text-[#02031E] mr-4" />
            <p className="text-lg text-[#02031E]">Ter segurança financeira</p>
          </div>
          <div
            className="flex items-center bg-white p-6 shadow-custom"
            data-aos="fade-up"
            data-aos-delay="500"
          >
            <FaBrain className="text-4xl text-[#02031E] mr-4" />
            <p className="text-lg text-[#02031E]">
              Ser mais consciente com seu dinheiro
            </p>
          </div>
          <div
            className="flex items-center bg-white p-6 shadow-custom"
            data-aos="fade-up"
            data-aos-delay="600"
          >
            <FaBolt className="text-4xl text-[#02031E] mr-4" />
            <p className="text-lg text-[#02031E]">Driblar crises financeiras</p>
          </div>
          <div
            className="flex items-center bg-white p-6 shadow-custom"
            data-aos="fade-up"
            data-aos-delay="700"
          >
            <FaStar className="text-4xl text-[#02031E] mr-4" />
            <p className="text-lg text-[#02031E]">
              Realizar seus maiores sonhos e objetivos
            </p>
          </div>

          <div
            className="flex items-center bg-white p-6 shadow-custom"
            data-aos="fade-up"
            data-aos-delay="700"
          >
            <FaChartBar className="text-4xl text-[#02031E] mr-4" />
            <p className="text-lg text-[#02031E]">
              Ter clareza através de gráficos e objetivos mensuráveis
            </p>
          </div>
        </div>
      </div>

      <div className="gradient-background mt-20 p-10 shadow-lg rounded-lg max-w-6xl mx-auto flex flex-col lg:flex-row items-center relative z-10">
        <div className="lg:w-1/2 mb-8 lg:mb-0 lg:pr-8" data-aos="fade-right">
          <h2 className="text-3xl font-bold mb-6 text-[#fff]">
            Tudo o que você precisa para organizar as suas finanças está em um
            só lugar
          </h2>
          <img src={CallToAction} loading="lazy" alt="Call To Action" />
        </div>
        <div className="lg:w-1/2 text-[#fff]" data-aos="fade-left">
          <p className="mb-6">
            Ferramentas prontas com aulas práticas desenvolvidas para ajudar as
            pessoas a organizarem suas finanças, fazer o dinheiro sobrar e criar
            um planejamento financeiro de um jeito fácil e rápido.
          </p>
          <h2 className="font-bold mb-4">Com nossas ferramentas você:</h2>
          <ul className="space-y-4" data-aos="fade-up">
            <li className="bg-[#02031e98] p-2 rounded-md font-bold">
              Fará o dinheiro sobrar
            </li>
            <li className="bg-[#02031e98] p-2 rounded-md font-bold">
              Pagará contas em dia
            </li>
            <li className="bg-[#02031e98] p-2 rounded-md font-bold">
              Gastará menos do que ganha
            </li>
            <li className="bg-[#02031e98] p-2 rounded-md font-bold">
              Saberá exatamente para onde vai o seu dinheiro
            </li>
            <li className="bg-[#02031e98] p-2 rounded-md font-bold">
              Economizará para sonhos
            </li>
            <li className="bg-[#02031e98] p-2 rounded-md font-bold">
              Acumulará para a reserva de emergência
            </li>
            <li className="bg-[#02031e98] p-2 rounded-md font-bold">
              Garantirá conforto e lazer para sua família
            </li>
          </ul>
          <p className="mt-4 font-bold uppercase text-center text-white bg-[#02031E] w-[130px] text-white px-1 rounded">
            E Muito Mais!
          </p>{" "}
        </div>
      </div>

      <div className="text-white mt-14 md:mt-14 text-center relative z-10">
        <h2 className="text-2xl md:text-3xl lg:text-4xl font-semibold mb-6 text-white text-center max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg mx-auto">
          Mesmo que você não saiba nada sobre planejamento financeiro, eu te
          garanto
          <br /> que a{" "}
          <span className="bg-[#02031E] text-white font-extrabold px-1 rounded">
            Planilha Controle Total vai transformar
          </span>{" "}
          a forma que você lida com o dinheiro!
        </h2>
        <div className="text-center mt-6 mb-lg">
          <a
            href="https://pay.kiwify.com.br/z5lQvhc"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="gradient-button uppercase text-white font-bold py-3 px-12 rounded-md border-b-4 border-transparent transition-all duration-300 hover:shadow-md hover:shadow-[#02031E80] hover:border-[#02031E]">
              Adquirir agora
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default FinancialBenefits;
