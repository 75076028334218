import React, { useEffect, useState, useRef } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { useInView } from "react-intersection-observer";

const FAQItem = ({ faq, index, activeIndex, toggleFAQ }) => {
  const { ref, inView } = useInView({ triggerOnce: false });
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef(null);

  useEffect(() => {
    if (inView) {
      AOS.refresh();
    }
  }, [inView]);

  useEffect(() => {
    setIsOpen(activeIndex === index);
  }, [activeIndex, index]);

  return (
    <div
      ref={ref}
      className="mb-4"
      data-aos="fade-up"
      data-aos-delay={`${index * 100}`}
    >
      <button
        onClick={() => {
          toggleFAQ(index);
          setIsOpen(prev => !prev);
        }}
        className="w-full text-left gradient-background-faq p-4 rounded-lg shadow-md focus:outline-none"
      >
        <h2 className="text-xl text-white">{faq.question}</h2>
      </button>
      <div
        className={`overflow-hidden transition-all duration-500 ease-in-out`}
        style={{
          maxHeight: isOpen ? contentRef.current.scrollHeight : 0,
        }}
      >
        <div
          ref={contentRef}
          className="p-4 bg-[#02031e] md:bg-[#02031e] rounded-lg shadow-inner"
        >
          <p className="text-white">{faq.answer}</p>
        </div>
      </div>
    </div>
  );
};

export default FAQItem;
